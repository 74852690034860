<template>
  <div
    class="min_h_100_vh"
    :style="`background:url('http://songcw-dev.oss-cn-shanghai.aliyuncs.com/car-agent/20220714/iiPJWERQulLnjumYffum.png') no-repeat center/cover`"
  >
    <!-- 插图 -->
    <div class="dis_flex ju_con_cen">
      <img
        class="w_288"
        src="http://songcw-dev.oss-cn-shanghai.aliyuncs.com/car-agent/20220714/VuuAidqtttqJryLDHRaJ.png"
      />
    </div>

    <!-- 标题 -->
    <div class="dis_flex ju_con_cen m_t_8">
      <img
        class="w_192"
        src="http://songcw-dev.oss-cn-shanghai.aliyuncs.com/car-agent/20220714/JVvcFgcitGXoDVuAKuqj.png"
      />
    </div>

    <!-- 三角 -->
    <div class="dis_flex ju_con_cen m_t_16">
      <img
        class="w_16 dis_flex"
        src="http://songcw-dev.oss-cn-shanghai.aliyuncs.com/car-agent/20220714/OBbqOAHkeEOnvawqYUMm.png"
      />
    </div>

    <!-- 输入 -->
    <div
      class="dis_flex flex_dir_col bg_col_fff bor_rad_16 m_0_16"
      style="margin-top: -1px"
    >
      <div class="m_0_16 m_t_32">
        <!-- 账号 -->
        <div class="dis_flex ali_it_cen p_8_0 m_0_16">
          <img
            class="w_24 h_24"
            src="http://songcw-dev.oss-cn-shanghai.aliyuncs.com/car-agent/20220714/VmDxvgWrAOwpnhNscZcO.png"
          />

          <input
            class="flex_1 font_14 bor_none bg_col_tra m_l_16"
            v-model="form.username"
            type="text"
            placeholder="请输入账号"
            maxlength="50"
          />

          <!--  -->
        </div>

        <!-- 灰线 -->
        <div class="h_1 bg_col_F5F7F9 m_0_64" />

        <!-- 密码 -->
        <div class="dis_flex ali_it_cen p_8_0 m_0_16 m_t_16">
          <img
            class="w_24 h_24"
            src="http://songcw-dev.oss-cn-shanghai.aliyuncs.com/car-agent/20220714/mHpiqATWokrxTBopWodM.png"
          />
          <input
            v-model="form.password"
            class="flex_1 font_14 bor_none bg_col_tra m_l_16"
            type="password"
            placeholder="请输入密码"
            maxlength="50"
          />
        </div>

        <!-- 灰线 -->
        <div class="h_1 bg_col_F5F7F9 m_0_64" />

        <!--  -->
      </div>

      <!-- 登录 -->
      <div
        class="
          dis_flex
          ju_con_cen
          font_16
          bg_col_2396F5
          col_fff
          bor_rad_24
          active_opa_75_per
          p_12_16
          m_0_16 m_t_32
        "
        @click="submit()"
      >
        登录
      </div>

      <!-- 留白 -->
      <div class="h_32"></div>

      <!--  -->
    </div>

    <!-- 留白 -->
    <div class="h_32"></div>

    <!--  -->
  </div>
</template>

<script>
import { Toast } from 'vant';
const moment = window.moment;

import { oauthTokenPost } from '@/api/index';

export default {
  components: {},
  data() {
    return {
      form: {},

      //
    };
  },
  computed: {},
  mounted() {
    const u = JSON.parse(localStorage.userInfo || '{}');

    // 有用户信息 且没过期 就直接前往
    if (u.access_token && moment().isBefore(u.expiresTime)) {
      this.$router.replace('/index');
    }

    //
  },
  methods: {
    // 提交
    async submit() {
      try {
        if (!this.form.username) {
          throw '请输入账号';
        }

        if (!this.form.password) {
          throw '请输入密码';
        }

        Toast.loading({
          duration: 10 * 1000,
          message: '请稍后..',
          forbidClick: true,
        });

        const data = await oauthTokenPost({
          username: this.form.username,
          password: this.form.password,
        });

        localStorage.userInfo = JSON.stringify({
          ...data,
          expiresTime: moment()
            .add(data.expires_in - 600, 'seconds') // 令牌有效期提前10分钟
            .format('YYYY-MM-DD HH:mm:ss'),
        });

        Toast('登录成功');

        this.$router.replace('/index');

        //
      } catch (err) {
        Toast(err);
      }

      //
    },

    //
  },
};
</script>
